import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { API_URL } from "../../config";

function Cardio() {
  const [allCardioDetail, setAllCardioDetails] = useState([]);
  const params = useParams();

  const getCardioDeatils = async () => {
    console.log(params);
    let result = await fetch(API_URL + `api/adultOnset/allcardio`);
    result = await result.json();
    setAllCardioDetails(result?.data);
    console.log("result", result);
  };

  useEffect(() => {
    getCardioDeatils();
  }, []);

  const navigate = useNavigate();
  const handleEdit = () => {
    navigate("/editcardio");
  };

  return (
    <div className="adultOnsetContainer" style={{height:"100vh"}}>
      <h4>Edit Cardio Post</h4>
      <span>Here you can edit the cardio details:</span>

      {allCardioDetail.map((item) => {
        return (
          <div>
            <table class="table text-center table-bordered mt-4">
              <thead>
                <tr>
                  <th scope="col">Sl No.</th>
                  <th scope="col">Page Title</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">1</td>
                  <td>Cardio Page</td>
                  <td>
                    <Link to={`/editcardio/${item._id}`}>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={handleEdit}
                      >
                        Edit Post
                      </button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}

export default Cardio;
