import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { API_URL } from "../../config";
import "../../app.css";

function Cancer() {
  const [allCancerPost, setAllCancerPost] = useState([]);
  const navigate = useNavigate();
  const handleEdit = () => {};

  const getData = async () => {
    let result = await fetch(API_URL + "api/adultOnset/allCancer");
    let res = await result.json();
    setAllCancerPost(res.data);
    console.log("result", res);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="adultOnsetContainer" style={{height:"100vh"}}>
      <h4>Edit Cancer Post</h4>
      <span>Here you can edit the cancer details:</span>
      {allCancerPost.map((item) => {
        return (
          <div>
            <table class="table text-center table-bordered mt-4">
              <thead>
                <tr>
                  <th scope="col">Sl No.</th>
                  <th scope="col">Page Title</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">1</td>
                  <td>Cancer Page</td>
                  <td className="">
                    <Link to={`/editcancer/${item._id}`}>
                      <button
                        className="btn btn-sm btn-primary "
                        onClick={handleEdit}
                      >
                        Edit Post
                      </button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}

export default Cancer;
