import React, { useState } from "react";
import { API_URL } from "../../config/index";
import { api_url } from "../../config/index";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../app.css";
import { toast } from "react-toastify";

function CreateArticle() {
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      console.log("createappend",file)
      let result = await axios.post(API_URL + "api/upload", formData);
      console.log("uplaod", result.data)
      return result.data;
    } catch (err) {
      console.log(err);
    }
  };

  //create article
  const handleCreate = async (e) => {
    e.preventDefault();
    const imgUrl = await upload();
    console.log("called", imgUrl);
    try {
      let result = await fetch(API_URL + "api/articles/createArticle", {
        method: "POST",
        body: JSON.stringify({
          title,
          description,
          category,
          file: imgUrl,
          tags,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("created successfully.");
      result =await result.json();
      console.log("result",result)
      navigate("/articles");
    } catch (err) {
      console.log(err);
    }
  };

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    };
  };

  const handleBack = () => {
    navigate("/articles");
  };

  return (
    <div>
      <button className="btn btn-primary ms-3 mt-2" onClick={handleBack}>
        <i className="fa-solid fa-backward me-2"></i>
        back
      </button>
      <div className="containerform">
        <h2 className="pageTitle">Add an article</h2>

        {/* "handleSubmit" will validate your inputs before invoking "onSubmit"  */}
        <form onSubmit={handleCreate} enctype="multipart/form-data" autoComplete="off">
          <div className="form-group">
            <label htmlFor="fullname" className="fw-bold">
              Title
            </label>
            <br />
            <input
              type="text"
              id="title"
              className="form-control mt-2"
              placeholder="Enter your question"
              required
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          {/* answer */}

          <div className="form-group" class="fw-bold">
            <label htmlFor="address" className="mt-2">
              Description
            </label>
            <br />
            <textarea
              name="description"
              id="description"
              rows={10}
              placeholder="Enter your Answer"
              className="form-control mt-2"
              required
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>

          {/* image upload */}
          <div className="mt-2 fw-bold">
            <div class="">Upload image</div>
            <div className="d-flex">
            <div class="mt-2">
              
                <input type="file" onChange={onFileChange} />
             
            </div>
            <div>
            <h6 className="" style={{marginTop:"-20px"}}>Preview Image: </h6>
                {/* <img src={file} width={200} height={100} /> */}
                <img
                  src={imagePreview}
                  width={400}
                  height={300}
                  alt="choose image"
                />
            </div>
            </div>
          </div>

          {/* category */}

          <div className="form-group mt-2">
            <label htmlFor="fullname" className="fw-bold">
              Category
            </label>
            <br />
            <input
              type="text"
              id="category"
              className="form-control mt-2"
              placeholder="Enter category here"
              required
              onChange={(e) => setCategory(e.target.value)}
            />
          </div>

          {/* tags */}

          <div className="form-group mt-2">
            <label htmlFor="fullname" className="fw-bold">
              Tags
            </label>
            <br />
            <input
              type="text"
              id="tags"
              className="form-control mt-2"
              placeholder="Enter tags here"
              required
              onChange={(e) => setTags(e.target.value)}
            />
          </div>

          <div className="form-group mt-2">
            <input
              type="submit"
              value="submit"
              className="btn btn-success border-radius form-control"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
export default CreateArticle;
