import React, { useEffect, useState, useRef } from "react";
import { API_URL } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

function CancerEdit() {
  const navigate = useNavigate();
  const [allCardioPost, setAllCardioPost] = useState({
    section1_title: "",
    section1_desc1: "",
    section1_desc2: "",
    section1_desc3: "",
    section1_desc4: "",
    section1_desc5: "",
    section1_desc6: "",
    section1_desc7: "",
    section2_title: "",
    section2_description: "",
    section2_title2: "",
    section2_description2: "",
    section2_title3: "",
    section2_description3: "",
    section2_image: [],
    section3_title: "",
    section3_image: [],
  });

  const {
    section1_title,
    section1_desc1,
    section1_desc2,
    section1_desc3,
    section1_desc4,
    section1_desc5,
    section1_desc6,
    section1_desc7,
    section2_title,
    section2_description,
    section2_title2,
    section2_description2,
    section2_title3,
    section2_description3,
    section2_image,
    section3_title,
    section3_image,
  } = allCardioPost;

  function changeText(e) {
    const { name, value } = e.target;
    setAllCardioPost({ ...allCardioPost, [name]: value });
  }

  const contentChange1 = (singleData) => {
    setAllCardioPost({ ...allCardioPost, section1_desc1: singleData });
  };

  const contentChange2 = (singleData) => {
    setAllCardioPost({ ...allCardioPost, section1_desc2: singleData });
  };

  const contentChange3 = (singleData) => {
    setAllCardioPost({ ...allCardioPost, section1_desc3: singleData });
  };

  const contentChange4 = (singleData) => {
    setAllCardioPost({ ...allCardioPost, section1_desc4: singleData });
  };

  const contentChange5 = (singleData) => {
    setAllCardioPost({ ...allCardioPost, section1_desc5: singleData });
  };

  const contentChange6 = (singleData) => {
    setAllCardioPost({ ...allCardioPost, section1_desc6: singleData });
  };

  const contentChange7 = (singleData) => {
    setAllCardioPost({ ...allCardioPost, section1_desc7: singleData });
  };

  const contentChange8 = (singleData) => {
    setAllCardioPost({ ...allCardioPost, section2_description: singleData });
  };

  const contentChange9 = (singleData) => {
    setAllCardioPost({ ...allCardioPost, section2_description2: singleData });
  };

  const contentChange10 = (singleData) => {
    setAllCardioPost({ ...allCardioPost, section2_description3: singleData });
  };
  const params = useParams();
  const editor = useRef(null);

  //get single post
  const getCardioPostDetails = async () => {
    console.log(params);
    let result = await fetch(
      API_URL + `api/adultOnset/singledatacardio/${params.id}`
    );
    result = await result.json();
    setAllCardioPost(result?.singleData);
    console.log(result);
  };
  useEffect(() => {
    getCardioPostDetails();
  }, []);

  const updateCardio = async (e) => {
    e.preventDefault();
    let result = await fetch(
      API_URL + `api/adultOnset/updateCardioPost/${params.id}`,
      {
        method: "PUT",
        body: JSON.stringify(allCardioPost),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    result = await result.json();
    toast.success("updated successfully.");
    navigate("/cardio");
  };

  const handleBack = () => {
    navigate("/cardio");
  };

  return (
    <div>
      <button className="btn btn-primary ms-3 mt-2" onClick={handleBack}>
        <i className="fa-solid fa-backward me-2"></i>
        back
      </button>

      <div className="containerform">
        <h2 className="pageTitle">Edit Cardio Sections</h2>

        {/* "handleSubmit" will validate your inputs before invoking "onSubmit"  */}
        <form onSubmit={(e) => updateCardio(e)} autoComplete="off">
          <div className="sectionHolder">
            <h4> Section 1</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section1_title"
                    value={section1_title}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-1
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCardioPost?.section1_desc1}
                    onChange={(newContent) => contentChange1(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-2
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCardioPost?.section1_desc2}
                    onChange={(newContent) => contentChange2(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-3
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCardioPost?.section1_desc3}
                    onChange={(newContent) => contentChange3(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-4
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCardioPost?.section1_desc4}
                    onChange={(newContent) => contentChange4(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-5
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCardioPost?.section1_desc5}
                    onChange={(newContent) => contentChange5(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-6
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCardioPost?.section1_desc6}
                    onChange={(newContent) => contentChange6(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-7
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCardioPost?.section1_desc7}
                    onChange={(newContent) => contentChange7(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 2</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title-1
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section1_title"
                    value={section2_title}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-1
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCardioPost?.section2_description}
                    onChange={(newContent) => contentChange8(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title-2
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section1_title"
                    value={section2_title2}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-2
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCardioPost?.section2_description2}
                    onChange={(newContent) => contentChange9(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title-3
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section1_title"
                    value={section2_title3}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-3
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCardioPost?.section2_description3}
                    onChange={(newContent) => contentChange10(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 3</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title-1
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section1_title"
                    value={section3_title}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="sectionHolder">
            <h4> Section 2</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2 mb-2">
                 Upload image 
                  </label>
                  <br />
                  <div style={{cursor:"pointer"}}>
                   <input type="file" />
                    <span>preview image</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="sectionHolder">
            <h4> Section 3</h4>
            <div className="card">
              <div className="card-body">
              <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2 mb-2">
                 Upload image 
                  </label>
                  <br />
                  <div style={{cursor:"pointer"}}>
                   <input type="file" />
                    <span>preview image</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="form-group mt-2">
            <input
              type="submit"
              value="submit"
              className="btn btn-success border-radius form-control"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CancerEdit;
