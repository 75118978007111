import React, { useEffect, useState, useRef } from "react";
import { API_URL } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

function CancerEdit() {
  const navigate = useNavigate();
  const [carrierRiskData, setCarrierRiskData] = useState({
    section1_title: "",
    section1_description: "",
    section1_image: [],
    quote: "",
    section2_title: "",
    section2_content1: "",
    section2_content2: "",
    section2_content3: "",
    section2_content4: "",
    section2_content5: "",
    section2_content6: "",
    section3_title: "",
    section3_desc1: "",
    section3_desc2: "",
    section3_desc3: "",
    section3_desc4: "",
    section4_title: "",
    section4_description:"",
    section5_title: "",
    section5_disease: "",
    section5_frequency: "",
    section5_totalDisorders: "",
    section5_inheritance: "",
    section6_title: "",
    section6_desc1: "",
    section6_desc2: "",
    section7_title: "",
    section8_title: "",
    section9_title: "",
    section9_img_title1: "",
    section9_img_desc1: "",
    section9_img_title2: "",
    section9_img_desc2: "",
    section9_img_title3: "",
    section9_img_desc3: "",
    section9_img_title4: "",
    section9_img_desc4: "",
    section9_img_title5: "",
    section9_img_desc5: "",
    section9_img1: [],
    section9_img2: [],
    section9_img3: [],
    section9_img4: [],
    section9_img5: [],
  });

  const {
    section1_title,
    section1_description,
    section1_image,
    quote,
    section2_title,
    section2_content1,
    section2_content2,
    section2_content3,
    section2_content4,
    section2_content5,
    section2_content6,
    section3_title,
    section3_desc1,
    section3_desc2,
    section3_desc3,
    section3_desc4,
    section4_title,
    section4_description,
    section5_title,
    section5_disease,
    section5_frequency,
    section5_totalDisorders,
    section5_inheritance,
    section6_title,
    section6_desc1,
    section6_desc2,
    section7_title,
    section8_title,
    section9_title,
    section9_img_title1,
    section9_img_desc1,
    section9_img_title2,
    section9_img_desc2,
    section9_img_title3,
    section9_img_desc3,
    section9_img_title4,
    section9_img_desc4,
    section9_img_title5,
    section9_img_desc5,
    section9_img1,
    section9_img2,
    section9_img3,
    section9_img4,
    section9_img5,
  } = carrierRiskData;

  function changeText(e) {
    const { name, value } = e.target;
    setCarrierRiskData({ ...carrierRiskData, [name]: value });
  }
  const contentChange = (singleData) => {
    setCarrierRiskData({
      ...carrierRiskData,
      section1_description: singleData,
    });
  };
  const contentChange1 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, quote: singleData });
  };

  const contentChange2 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, section3_desc1: singleData });
  };
  const contentChange3 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, section3_desc2: singleData });
  };
  const contentChange4 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, section3_desc3: singleData });
  };
  const contentChange5 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, section3_desc4: singleData });
  };
  const contentChange6 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, section6_desc1: singleData });
  };
  const contentChange7 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, section6_desc2: singleData });
  };

  const contentChange8 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, section9_img_desc1: singleData });
  };
  const contentChange9 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, section9_img_desc2: singleData });
  };
  const contentChange10 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, section9_img_desc3: singleData });
  };
  const contentChange11 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, section9_img_desc4: singleData });
  };
  const contentChange12 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, section9_img_desc5: singleData });
  };
  const contentChange13 = (singleData) => {
    setCarrierRiskData({ ...carrierRiskData, section4_description: singleData });
  };

  const params = useParams();
  const editor = useRef(null);

  //get single post
  const getCarrierRiskPostDetails = async () => {
    console.log(params);
    let result = await fetch(
      API_URL + `api/carrierrisk/singledata/${params.id}`
    );
    result = await result.json();
    setCarrierRiskData(result?.singleData);
    console.log(result);
  };
  useEffect(() => {
    getCarrierRiskPostDetails();
  }, []);

  const updateCarrierRisk = async (e) => {
    e.preventDefault();
    let result = await fetch(API_URL + `api/carrierrisk/update/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(carrierRiskData),
      headers: {
        "Content-Type": "application/json",
      },
    });
    result = await result.json();
    toast.success("updated successfully.");
    navigate("/carrierrisk");
  };

  const handleBack = () => {
    navigate("/carrierrisk");
  };

  return (
    <div>
      <button className="btn btn-primary ms-3 mt-2" onClick={handleBack}>
        <i className="fa-solid fa-backward me-2"></i>
        back
      </button>

      <div className="containerform">
        <h2 className="pageTitle">Edit Carrier Risk Sections</h2>

        {/* "handleSubmit" will validate your inputs before invoking "onSubmit"  */}
        <form onSubmit={(e) => updateCarrierRisk(e)} autoComplete="off">
          <div className="sectionHolder">
            <h4> Section 1</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section1_title"
                    value={section1_title}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section1_description}
                    onChange={(newContent) => contentChange(newContent)}
                  />
                </div>
                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-3 mb-2">
                 Upload image 
                  </label>
                  <br />
                  <div style={{cursor:"pointer"}}>
                   <input type="file" />
                    <span>preview image</span>
                  </div>
                </div> */}
                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-3">
                    Quote
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.quote}
                    onChange={(newContent) => contentChange1(newContent)}
                  />
                </div> */}
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 2</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_title"
                    value={section2_title}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    content-1
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_content1"
                    value={section2_content1}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    content-2
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_content2"
                    value={section2_content2}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    content-3
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_content3"
                    value={section2_content3}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    content-4
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_content4"
                    value={section2_content4}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    content-5
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_content5"
                    value={section2_content5}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    content-6
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_content6"
                    value={section2_content6}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 3</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section3_title"
                    value={section3_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-1
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section3_desc1}
                    onChange={(newContent) => contentChange2(newContent)}
                  />
                </div>{" "}
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-2
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section3_desc2}
                    onChange={(newContent) => contentChange3(newContent)}
                  />
                </div>{" "}
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-3
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section3_desc3}
                    onChange={(newContent) => contentChange4(newContent)}
                  />
                </div>{" "}
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-4
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section3_desc4}
                    onChange={(newContent) => contentChange5(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 4</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section4_title"
                    value={section4_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section4_description}
                    onChange={(newContent) => contentChange13(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 5</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section5_title"
                    value={section5_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Disease
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section5_disease"
                    value={section5_disease}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div> */}
                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Frequency
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section5_frequency"
                    value={section5_frequency}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div> */}
                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Total disorders
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section5_totalDisorders"
                    value={section5_totalDisorders}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div> */}
                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Inheritance
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section5_inheritance"
                    value={section5_inheritance}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div> */}
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 6</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section5_title"
                    value={section5_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-1
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section6_desc1}
                    onChange={(newContent) => contentChange6(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-2
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section6_desc2}
                    onChange={(newContent) => contentChange7(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 7</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section5_title"
                    value={section7_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionHolder">
            <h4> Section 8</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section5_title"
                    value={section8_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 9</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section5_title"
                    value={section9_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    image-1-title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section9_img_title1"
                    value={section9_img_title1}
                    id="title"
                    placeholder="Enter your Title"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-3 mb-2">
                 Upload image 
                  </label>
                  <br />
                  <div style={{cursor:"pointer"}}>
                   <input type="file" />
                    <span>preview image</span>
                  </div>
                </div> */}
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    image-1-desc
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section9_img_desc1}
                    onChange={(newContent) => contentChange8(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    image-2-title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section9_img_title2"
                    value={section9_img_title2}
                    id="title"
                    placeholder="Enter your Title"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-3 mb-2">
                 Upload image 
                  </label>
                  <br />
                  <div style={{cursor:"pointer"}}>
                   <input type="file" />
                    <span>preview image</span>
                  </div>
                </div> */}
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    image-2-desc
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section9_img_desc2}
                    onChange={(newContent) => contentChange9(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    image-3-title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section9_img_title3"
                    value={section9_img_title3}
                    id="title"
                    placeholder="Enter your Title"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-3 mb-2">
                 Upload image 
                  </label>
                  <br />
                  <div style={{cursor:"pointer"}}>
                   <input type="file" />
                    <span>preview image</span>
                  </div>
                </div> */}
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    image-3-desc
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section9_img_desc3}
                    onChange={(newContent) => contentChange10(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    image-4-title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section9_img_title4"
                    value={section9_img_title4}
                    id="title"
                    placeholder="Enter your Title"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-3 mb-2">
                 Upload image 
                  </label>
                  <br />
                  <div style={{cursor:"pointer"}}>
                   <input type="file" />
                    <span>preview image</span>
                  </div>
                </div> */}
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    image-4-desc
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section9_img_desc4}
                    onChange={(newContent) => contentChange11(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    image-5-title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section9_img_title5"
                    value={section9_img_title5}
                    id="title"
                    placeholder="Enter your Title"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-3 mb-2">
                 Upload image 
                  </label>
                  <br />
                  <div style={{cursor:"pointer"}}>
                   <input type="file" />
                    <span>preview image</span>
                  </div>
                </div> */}
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    image-5-desc
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={carrierRiskData?.section9_img_desc5}
                    onChange={(newContent) => contentChange12(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mt-2">
            <input
              type="submit"
              value="submit"
              className="btn btn-success border-radius form-control"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CancerEdit;
