import React, { useState } from "react";
import { useEffect } from "react";
import { API_URL } from "../../config/index";
import TablePagination from "@mui/material/TablePagination";

function ContactUs() {
  const [consults, setConsults] = useState([]);
  //pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const getDetails = async () => {
    let result = await fetch(API_URL + "api/consult/getall");
    let res = await result.json();
    setConsults(res.data);
    console.log("all consults", res);
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className="wrapper">
      <div className="d-flex justify-content-between align-items-center">
      <h2 className="pageTitle">Consult information</h2>
      <p className="text-primary">page: <b>{page+1}</b></p>
      </div>

      <div className="row mt-3">
        <div className="table-responsive">
          <table className="table text-dark rounded-3 table-bordered ">
            {consults.length > 0 ? (
              <thead className="fs-5">
                <tr>
                  <th scope="col">Sl.No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Message</th>
                </tr>
              </thead>
            ) : (
              ""
            )}
            <tbody>
              {consults.length > 0 ? (
                consults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, id) => {
                  return (
                    <>
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.mobile}</td>
                        <td>{item.message}</td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <h3 className="text-danger">No Result Found</h3>
              )}
            </tbody>
          </table>

          {consults.length > 10 ? (
            <TablePagination
           sx={{display:"flex", justifyContent:"center", alignItems:"center", background:"skyblue", height:"auto"}}
              rowsPerPageOptions={[10, 20]}
              component="div"
              count={consults.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            ""
          )}
        </div>
      </div>     

    </div>
  );
}

export default ContactUs;
