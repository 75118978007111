import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../config";
import { api_url } from "../../config";
import moment from "moment";
import "../../app.css";
import CreateArticle from "./CreateArticle";
import EditArticle from "./EditArticle";
import { toast } from "react-toastify";

function Article() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [allArticles, setAllArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState();

  function openClose() {
    setOpen(true);
    navigate("/");
  }

  //get all articles
  const getData = async () => {
    let result = await fetch(API_URL + "api/articles/getAll");
    let res = await result.json();
    setAllArticles(res.data);
    console.log("result", res);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleEdit = () => {
    navigate("/editarticle");
  };

  //   delete
  async function deleteArticle(id) {
    let result = await fetch(API_URL + `api/articles/deleteArticle/${id}`, {
      method: "Delete",
    });
    result = await result.json();
    toast.success("deleted successfully.");
    console.log("deleted result", result);
    if (result) {
      getData();
    }
  }
  
  return (
    <>
      {allArticles.length > 0 ? (
        <div className="wrapper">
          <div className="d-flex justify-content-between align-content-center">
            <h2 className="pageTitle">All Articles </h2>
            <Link to="/createArticle">
              <div onClick={openClose}>
                {/* Create a new faq */}
                <i class="fa-solid fa-circle-plus fa-3x"></i>
                {open ? <CreateArticle /> : ""}
              </div>
            </Link>
          </div>
          <div className="">
            {allArticles.map((item, i) => {
              return (
                <div className="pb-2">
                  <div
                    class="card pb-1"
                    style={{
                      width: "auto",
                      border: "2px solid rgb(155 155 155 / 30%)",
                    }}
                  >
                    <div className="card-header">
                      <div className="imageContainer">
                        <img
                          src={`/uploads/${item.file}`}
                          class="card-img-top"
                          alt="No image found"
                        />
                      </div>
                      <div class="card-body p-0 pt-2">
                        <h5 class="card-title">
                          <b>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            />
                          </b>
                        </h5>
                        <p class="card-text">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </p>
                        <div style={{ color: "#000" }}>
                          <p className="d-flex">
                            <b>Category:</b>
                            <div
                              style={{ marginLeft: "4px" }}
                              dangerouslySetInnerHTML={{
                                __html: item.category,
                              }}
                            />
                          </p>

                          <div style={{ display: "flex" }}>
                            <p>
                              <b>Tags</b>
                            </p>
                            :
                            <p style={{ color: "#00d4ff", marginLeft: "4px" }}>
                              <div
                                dangerouslySetInnerHTML={{ __html: item.tags }}
                              />
                            </p>
                          </div>
                        </div>
                        <p className="d-flex" style={{ fontSize: "14px" }}>
                          <b>CreatedAt :</b>
                          <div className="ms-1">
                            {moment(item.createdAt).format("L , LT")}
                          </div>
                        </p>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Link to={`/editarticle/${item._id}`}>
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={handleEdit}
                            >
                              Edit
                            </button>
                          </Link>

                          <button
                            className="btn btn-sm btn-danger ms-3"
                            onClick={() => {
                              window.confirm(
                                "Are you sure want to delete this article?"
                              ) && deleteArticle(item._id);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="wrapper">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="pageTitle">All Articles </h2>
              <Link to="/createArticle">
                <div onClick={openClose}>
                  {/* Create a new faq */}
                  <i class="fa-solid fa-circle-plus fa-3x"></i>
                  {open ? <CreateArticle /> : ""}
                </div>
              </Link>
            </div>
            <h4 className="text-danger text-center">No Article Found</h4>
            <h6 className="text-center text-primary">
              ( to create an article click the ➕ button at top right )
            </h6>
          </div>
        </div>
      )}
    </>
  );
}

export default Article;
