import "./sidebar.scss";
import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

const Sidebar = () => {
  // const { dispatch } = useContext(DarkModeContext);
  const [anchorEl, setAnchorEl] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="sidebar">
      <div className="logo">
        <img src="/images/strandlogo-1.png" />
      </div>
      <hr />
      <div className="center">
        <ul>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>

          <p className="title mt-3">CMS</p>
          <Button
            sx={{
              textTransform: "capitalize",
              padding: 0,
              margin: 0,
              background: "none",
            }}
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <li>
              <i class="fa-solid fa-users"></i>
              <span>Adult Onset</span>
              <i class="fa-solid fa-caret-down ms-1"></i>
            </li>
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <Link to={`/cancer`} style={{ textDecoration: "none" }}>
              <MenuItem onClick={handleClose}>
                <li>
                  <i
                    class="fa-solid fa-disease"
                    style={{ color: "#7451f8" }}
                  ></i>
                  <span
                    style={{ color: "#888", marginLeft: 6, fontWeight: 500 }}
                  >
                    Cancer
                  </span>
                </li>
              </MenuItem>
            </Link>
            <Link to="/cardio" style={{ textDecoration: "none" }}>
              <MenuItem onClick={handleClose}>
                <li>
                  <i
                    class="fa-solid fa-heart-circle-bolt"
                    style={{ color: "#7451f8" }}
                  ></i>
                  <span
                    style={{ color: "#888", marginLeft: 6, fontWeight: 500 }}
                  >
                    Cardio
                  </span>
                </li>
              </MenuItem>
            </Link>
            <Link to="/metabolism" style={{ textDecoration: "none" }}>
              <MenuItem onClick={handleClose}>
                <li>
                  {/* <i class="fa-regular fa-user"></i> */}
                  <img width={18} src="/images/metabolism_4.png" alt="" />
                  <span
                    style={{ color: "#888", marginLeft: 6, fontWeight: 500 }}
                  >
                    Metabolism
                  </span>
                </li>
              </MenuItem>
            </Link>
          </Menu>

          <Link to="/carrierrisk" style={{ textDecoration: "none" }}>
            <li>
              <i class="fa-solid fa-notes-medical"></i>
              <span>Career Risk</span>
            </li>
          </Link>
          <Link to="/pharmacogenomics" style={{ textDecoration: "none" }}>
            <li>
              <i class="fa-solid fa-dna"></i>
              <span>Pharmacogenomics</span>
            </li>
          </Link>

          <Link to="/faqs" style={{ textDecoration: "none" }}>
            <li>
              <i class="fa-regular fa-circle-question"></i>
              <span>FAQ's</span>
            </li>
          </Link>

          {/* <Link to="/articles" style={{ textDecoration: "none" }}>
            <li>
              <i class="fa-regular fa-newspaper"></i>
              <span>Articles</span>
            </li>
          </Link> */}

          <p className="title mt-3">ENQUIRY</p>
          <Link to="/contact-us" style={{ textDecoration: "none" }}>
            <li>
              <i class="fa-solid fa-address-book"></i>

              <span>Contact</span>
            </li>
          </Link>
          <Link to="/consult" style={{ textDecoration: "none" }}>
            <li>
              <img src="/images/phone-call.png" alt="" />

              <span>Consult</span>
            </li>
          </Link>

        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
