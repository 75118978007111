import Login from "./pages/login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import Faq from ".././src/components/faq/Faq";
import Article from "./components/Articles/Article";
import Sidebar from "./components/sidebar/Sidebar";
import { Grid } from "@mui/material";
import Navbar from "./components/navbar/Navbar";
import CreateFaqForm from "./components/faq/CreateFaqForm";
import EditFaq from "./components/faq/EditFaq";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./components/Dashboard/Dashboard";
import CreateArticle from "./components/Articles/CreateArticle";
import EditArticle from "./components/Articles/EditArticle";
import Cancer from "./components/AdultOnset/Cancer";
import Cardio from "./components/AdultOnset/Cardio";
import CancerEdit from "./components/AdultOnset/CancerEdit";
import CardioEdit from "./components/AdultOnset/CardioEdit";
import Metabolic from "./components/AdultOnset/Metabolic";
import MetabolicEdit from "./components/AdultOnset/MetaboliEdit";
import CarrierRisk from "./components/CarrierRisk/CarrierRisk";
import CarrierRiskEdit from "./components/CarrierRisk/CarrierRiskEdit";
import ContactUs from "./components/Contact-Us/ContactUs";
import Consult from "./components/consult/Consult";
import Pharmacogenomics from "./components/Pharmagenomics/Pharmacogenomics";
import EditPharmacogenomics from "./components/Pharmagenomics/EditPharmacogenomics";
import AccountDetails from "./components/My-account/AccountDetails";
import ScrollToTop from "./scrolltotop/Scrolltotop";
import ScrollToTopNew from "./scrolltotop/ScrollToTopButton";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  // TODO check for login using some localstorage
  const isLoggedin = localStorage.getItem("isLoggedin");

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <Grid container>
        <BrowserRouter>
          <ToastContainer position={"top-center"} autoClose={1000} />
          {isLoggedin === "true" && (
            <Grid item md={2}>
              {/* rgb(155 155 155 / 12%) */}
              <Sidebar />
            </Grid>
          )}

          <Grid
            item
            md={isLoggedin === "true" ? 10 : 12}
            sx={{ backgroundColor: "#e3e7eb", height: "auto" }}
          >
            <Navbar />
            <Grid sx={{ backgroundColor: "#fff", borderRadius: 1, margin: 2 }}>
              <ScrollToTop/>
              <ScrollToTopNew />
              <Routes>
                {isLoggedin === "true" ? (
                  <>
                    {" "}
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="faqs" element={<Faq />} />
                    <Route path="/createFaq" element={<CreateFaqForm />} />
                    <Route path="editFaq/:id" element={<EditFaq />} id />
                    <Route path="articles" element={<Article />} />
                    <Route path="createArticle" element={<CreateArticle />} />
                    <Route path="editarticle/:id" element={<EditArticle />} />
                    <Route path="/cancer" element={<Cancer />} />
                    <Route path="/editcancer/:id" element={<CancerEdit />} />
                    <Route path="/cardio" element={<Cardio />} />
                    <Route path="/editcardio/:id" element={<CardioEdit />} />
                    <Route path="/metabolism" element={<Metabolic />} />
                    <Route
                      path="/editmetabolic/:id"
                      element={<MetabolicEdit />}
                    />
                    <Route path="/carrierrisk" element={<CarrierRisk />} />
                    <Route
                      path="/editcarrierrisk/:id"
                      element={<CarrierRiskEdit />}
                    />
                    <Route
                      path="/pharmacogenomics"
                      element={<Pharmacogenomics />}
                    />
                    <Route
                      path="/editpharmacogenomics/:id"
                      element={<EditPharmacogenomics />}
                    />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/consult" element={<Consult />} />
                    <Route
                      path="/accountdetails"
                      element={<AccountDetails />}
                    />
                  </>
                ) : (
                  <Route path="/">
                    <Route
                      index
                      element={isLoggedin === "true" ? "" : <Login />}
                    />
                    <Route path="login" element={<Login />} />
                  </Route>
                )}
              </Routes>
            </Grid>
          </Grid>
        </BrowserRouter>
      </Grid>
    </div>
  );
}

export default App;
