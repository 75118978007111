import ScrollToTop from "react-scroll-to-top";

function ScrollToTopNew() {
  return (
    <div style={{color:"red"}}>
      <ScrollToTop smooth color="white" height="16" style={{background:"black"}}/>
    </div>
  );
}

export default ScrollToTopNew