import "./login.scss";
import React, { useState } from "react";
import { API_URL } from "../../config/index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Login() {
  const navigate = useNavigate();
  // const [admins, setAdmins] = useState({
  //   email: "",
  //   password: "",
  // });
  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const changeText = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const { email, password } = formValues;

  const submitHandler = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("called");
    let result = await fetch(API_URL + "api/user/login", {
      method: "POST",
      body: JSON.stringify(formValues),
      headers: {
        "Content-Type": "application/json",
      },
    });
    result = await result.json();
    // if(result.accessToken){
    //   localStorage.setItem("token",JSON.stringify(result.accessToken));
    //   toast.success("Login successfully")
    //   navigate("/faqs")
    //   window.location.reload(false)
    // }else{
    //   toast.error("Invalid Login")
    // }
    // localStorage.setItem("isLoggedin", "true");
    //   toast.success("login successfully");
    //   navigate("/faqs");
    //   window.location.reload(false);

    if (result.accessToken) {
      localStorage.setItem("isLoggedin", "true");
      toast.success("login successfully");
      navigate("/dashboard");
      window.location.reload(false);
    } else {
      toast.error("Invalid Login");
    }

    console.log("resultAdminLogin", result.accessToken);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }

    if (!values.password) {
      errors.password = "password is required";
    }
    return errors;
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card card-login">
              <div className="card-body">
                <form onSubmit={(e) => submitHandler(e)} autoComplete="off">
                  <div className=" text-center">
                    <img className="text-center" src="/images/strandlogo.png" />
                  </div>
                  <h2
                    className="mt-4"
                    style={{
                      textAlign: "center",
                      color: "#0d6efd",
                      paddingBottom: 30,
                    }}
                  >
                    Login
                  </h2>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => changeText(e)}
                      className="form-control"
                    />
                  </div>
                  {
                  
                    email?"": (<p className="text-danger">{formErrors.email}</p>)
                  }
                  <div className="form-group mt-2 pb-2">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={(e) => changeText(e)}
                      className="form-control"
                    />
                  </div>
                  {
                    password?"":(<p className="text-danger">{formErrors.password}</p>)
                  }

                  <div className="form-group mt-2">
                    <input
                      type="submit"
                      value="login"
                      className="btn btn-primary fw-bold form-control"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
