import React, { useEffect, useState, useRef } from "react";
import { API_URL } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

function MetabolicEdit() {
  const navigate = useNavigate();
  const [metabolicPost, setMetabolicPost] = useState({
    section1_title: "",
    section1_description: "",
    section2_title: "",
    section2_desc1: "",
    section2_desc2: "",
    section2_desc3: "",
    section2_desc4: "",
    section2_desc5: "",
    section2_desc6: "",
    section2_desc7: "",
    section2_desc8: "",
    section2_desc9: "",
    section2_desc10: "",
    section2_desc11: "",
    section2_desc12: "",
    section3_title: "",
    section3_subtitle: "",
    section3_description: "",
    section3_example: "",
    section3_description2: "",
    section3_subtitle2: "",
    section3_subtitle3: "",
    section3_description3: "",
    section3_subdesc1: "",
    section3_subdesc2: "",
    section3_subdesc3: "",
    section4_title: "",
    section4_desc1: "",
    section4_desc2: "",
    section4_desc3: "",
    section4_example: "",
    section4_desc4: "",
    section4_image: [],
  });

  const {
    section1_title,
    section1_description,
    section2_title,
    section2_desc1,
    section2_desc2,
    section2_desc3,
    section2_desc4,
    section2_desc5,
    section2_desc6,
    section2_desc7,
    section2_desc8,
    section2_desc9,
    section2_desc10,
    section2_desc11,
    section2_desc12,
    section3_title,
    section3_subtitle,
    section3_description,
    section3_example,
    section3_description2,
    section3_subtitle2,
    section3_subtitle3,
    section3_description3,
    section3_subdesc1,
    section3_subdesc2,
    section3_subdesc3,
    section4_title,
    section4_desc1,
    section4_desc2,
    section4_desc3,
    section4_example,
    section4_desc4,
    section4_image,
  } = metabolicPost;

  function changeText(e) {
    const { name, value } = e.target;
    setMetabolicPost({ ...metabolicPost, [name]: value });
  }

  const contentChange1 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section1_description: singleData });
  };
  const contentChange2 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section2_desc1: singleData });
  };
  const contentChange3 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section2_desc2: singleData });
  };
  const contentChange4 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section2_desc3: singleData });
  };
  const contentChange5 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section2_desc4: singleData });
  };
  const contentChange6 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section2_desc5: singleData });
  };
  const contentChange7 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section2_desc6: singleData });
  };
  const contentChange8 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section2_desc7: singleData });
  };
  const contentChange9 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section2_desc8: singleData });
  };
  const contentChange10 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section2_desc9: singleData });
  };
  const contentChange11 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section2_desc10: singleData });
  };
  const contentChange12 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section2_desc11: singleData });
  };
  const contentChange13 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section2_desc12: singleData });
  };
  const contentChange14 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section3_description: singleData });
  };
  const contentChange15 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section3_description2: singleData });
  };
  const contentChange16 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section3_description3: singleData });
  };
  const contentChange17 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section3_subdesc1: singleData });
  };
  const contentChange18 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section3_subdesc2: singleData });
  };
  const contentChange19 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section3_subdesc3: singleData });
  };
  const contentChange20 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section4_desc1: singleData });
  };
  const contentChange21 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section4_desc2: singleData });
  };
  const contentChange22 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section4_desc3: singleData });
  };
  const contentChange23 = (singleData) => {
    setMetabolicPost({ ...metabolicPost, section4_desc4: singleData });
  };

  const params = useParams();
  const editor = useRef(null);

  //get single post
  const getMetabolicPostDetails = async () => {
    console.log(params);
    let result = await fetch(
      API_URL + `api/adultOnset/singledatametabolic/${params.id}`
    );
    result = await result.json();
    setMetabolicPost(result?.singleData);
    console.log(result);
  };
  useEffect(() => {
    getMetabolicPostDetails();
  }, []);

  const updateMetabolic = async (e) => {
    e.preventDefault();
    let result = await fetch(
      API_URL + `api/adultOnset/updateMetabolicPost/${params.id}`,
      {
        method: "PUT",
        body: JSON.stringify(metabolicPost),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    result = await result.json();
    toast.success("updated successfully.");
    navigate("/metabolism");
  };

  const handleBack = () => {
    navigate("/metabolism");
  };

  return (
    <div>
      <button className="btn btn-primary ms-3 mt-2" onClick={handleBack}>
        <i className="fa-solid fa-backward me-2"></i>
        back
      </button>

      <div className="containerform">
        <h2 className="pageTitle">Edit Metabolism Sections</h2>

        {/* "handleSubmit" will validate your inputs before invoking "onSubmit"  */}
        <form onSubmit={(e) => updateMetabolic(e)} autoComplete="off">
          <div className="sectionHolder">
            <h4> Section 1</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section1_title"
                    value={section1_title}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section1_description}
                    onChange={(newContent) => contentChange1(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 2</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_title"
                    value={section2_title}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-1
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section2_desc1}
                    onChange={(newContent) => contentChange2(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-2
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section2_desc2}
                    onChange={(newContent) => contentChange3(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-3
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section2_desc3}
                    onChange={(newContent) => contentChange4(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-4
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section2_desc4}
                    onChange={(newContent) => contentChange5(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-5
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section2_desc5}
                    onChange={(newContent) => contentChange6(newContent)}
                  />
                </div>{" "}
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-6
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section2_desc6}
                    onChange={(newContent) => contentChange7(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-7
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section2_desc7}
                    onChange={(newContent) => contentChange8(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-8
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section2_desc8}
                    onChange={(newContent) => contentChange9(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-9
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section2_desc9}
                    onChange={(newContent) => contentChange10(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-10
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section2_desc10}
                    onChange={(newContent) => contentChange11(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-11
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section2_desc11}
                    onChange={(newContent) => contentChange12(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-12
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section2_desc12}
                    onChange={(newContent) => contentChange13(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 3</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section3_title"
                    value={section3_title}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Sub-Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section3_subtitle"
                    value={section3_subtitle}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section3_description}
                    onChange={(newContent) => contentChange14(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Example
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section3_example"
                    value={section3_example}
                    placeholder="Enter your example"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Subtitle-2
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section3_subtitle2"
                    value={section3_subtitle2}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-2
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section3_description2}
                    onChange={(newContent) => contentChange15(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Subtitle-3
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section3_subtitle3"
                    value={section3_subtitle3}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-3
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section3_description3}
                    onChange={(newContent) => contentChange16(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    subDescription-1
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section3_subdesc1}
                    onChange={(newContent) => contentChange17(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    subDescription-2
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section3_subdesc2}
                    onChange={(newContent) => contentChange18(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    subDescription-3
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section3_subdesc3}
                    onChange={(newContent) => contentChange19(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 4</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section4_title"
                    value={section4_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-1
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section4_desc1}
                    onChange={(newContent) => contentChange20(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-2
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section4_desc2}
                    onChange={(newContent) => contentChange21(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-3
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section4_desc3}
                    onChange={(newContent) => contentChange22(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Example
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section4_example"
                    value={section4_example}
                    placeholder="Enter your example"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description-4
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={metabolicPost?.section4_desc4}
                    onChange={(newContent) => contentChange23(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mt-2">
            <input
              type="submit"
              value="submit"
              className="btn btn-success border-radius form-control"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default MetabolicEdit;
