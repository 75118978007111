import React, { useEffect, useState } from "react";
import { API_URL } from "../../config";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function EditFaq() {
  const navigate = useNavigate();
  const [updateData, setUpdateData] = useState({
    question: "",
    answer: "",
  });
  const { question, answer } = updateData;

  function changeText(e) {
    const { name, value } = e.target;
    setUpdateData({ ...updateData, [name]: value });
  }

  const params = useParams();

  const getFaqDetails = async () => {
    console.log(params);
    let result = await fetch(API_URL + `api/faq/faqs/${params.id}`);
    result = await result.json();
    setUpdateData(result.singleData);
    console.log(result);
  };

  useEffect(() => {
    getFaqDetails();
  }, []);

  //update

  const updateFaq = async (e) => {
    e.preventDefault();
    let result = await fetch(API_URL + `api/faq/updateFaq/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(updateData),
      headers: {
        "Content-Type": "application/json",
      },
    });
    result = await result.json();
    toast.success("faq updated successfully");
    setUpdateData({ question: "", answer: "" });
    navigate("/faqs");
  };

  const handleBack = () => {
    navigate("/faqs");
  };

  return (
    <>
      <button className="btn btn-primary ms-3 mt-2" onClick={handleBack}>
        <i className="fa-solid fa-backward me-2"></i>
        back
      </button>
      <div className="containerform">
        <h2 className="pageTitle">Edit Frequently Asked Question's</h2>

        {/* "handleSubmit" will validate your inputs before invoking "onSubmit"  */}
        <form onSubmit={(e) => updateFaq(e)} autoComplete="off">
          <div className="form-group">
            <label htmlFor="fullname" className="fw-bold">
              Question
            </label>
            <br />
            <input
              type="text"
              id="question"
              className="form-control border-dark mt-2"
              placeholder="Enter your question"
              name="question"
              value={question}
              onChange={changeText}
            />
          </div>

          {/* answer */}

          <div className="form-group" className="fw-bold">
            <label htmlFor="address" className="mt-2">
              Answer
            </label>
            <br />
            <textarea
              name="answer"
              id="answer"
              rows={10}
              placeholder="Enter your Answer"
              className="form-control border-dark mt-2"
              value={answer}
              onChange={changeText}
            ></textarea>
          </div>

          <div className="form-group mt-2">
            <input
              type="submit"
              value="update"
              className="btn btn-primary border-radius form-control"
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default EditFaq;
