import "./navbar.scss";
import { Link, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";

const Navbar = () => {
  const isLoggedin = localStorage.getItem("isLoggedin");
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg linearcolor sticky-top">
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto me-5 fs-5  fw-bold">
              {isLoggedin === null && (
                <li className="nav-item">
                  <Link className="nav-link text-dark" to={"/login"}>
                    Login
                    <i
                      style={{ marginLeft: "5px" }}
                      class="fa-solid fa-right-to-bracket"
                    ></i>
                  </Link>
                </li>
              )}

              {isLoggedin === "true" && (
                <div>
                  <div
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className="admin-style"
                  >
                    Admin <i class="fa-solid fa-caret-down"></i>
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <Link
                      to="/accountdetails"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem onClick={handleClose}>My account</MenuItem>
                    </Link>
                    <MenuItem onClick={handleClose}>
                      <li className="nav-item">
                        <Link
                          className="nav-link text-dark"
                          onClick={() => logout()}
                        >
                          Logout{" "}
                          <i
                            style={{ marginLeft: "2px" }}
                            class="fa-solid fa-right-from-bracket"
                          ></i>
                        </Link>
                      </li>
                    </MenuItem>
                  </Menu>
                </div>
              )}
              {/* {isAuthenticated && <UserOptions user={user} />} */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
