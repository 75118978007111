import React, { useEffect, useState,useRef } from "react";
//need to change the api url from localhost to server
import { API_URL } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

function CancerEdit() {
  const navigate = useNavigate();
  const [pharmacogenomicsData, setPharmacogenomicsData] = useState({
    section1_title:"",
    section1_desc:"",
    section2_title:"",
    section2_desc:"",
    section2_subDesc1:"",
    section2_subDesc2:"",
    section2_subDesc3:"",
    section3_title:"",
    section3_fact1:"",
    section3_fact2:"",
    section3_fact3:"",
    section4_title:"",
    section5_title:"",
    section5_desc1:"",
    section5_desc2:"",
    section5_desc3:"",
    section5_desc4:"",
    section5_desc5:"",
    section5_desc6:"",
    section6_title:"",
  });

  const {
    section1_title,
    section1_desc,
    section2_title,
    section2_desc,
    section2_subDesc1,
    section2_subDesc2,
    section2_subDesc3,
    section3_title,
    section3_fact1,
    section3_fact2,
    section3_fact3,
    section4_title,
    section5_title,
    section5_desc1,
    section5_desc2,
    section5_desc3,
    section5_desc4,
    section5_desc5,
    section5_desc6,
    section6_title,
  } = pharmacogenomicsData;

  function changeText(e) {
    const { name, value } = e.target;
    setPharmacogenomicsData({ ...pharmacogenomicsData, [name]: value });
  }

  const contentChange1 = (singleData) => {
    setPharmacogenomicsData({ ...pharmacogenomicsData, section1_desc: singleData });
  };
  const contentChange2 = (singleData) => {
    setPharmacogenomicsData({ ...pharmacogenomicsData, section2_desc: singleData });
  };
  const contentChange3 = (singleData) => {
    setPharmacogenomicsData({ ...pharmacogenomicsData, section2_subDesc1: singleData });
  };
  
  const contentChange4 = (singleData) => {
    setPharmacogenomicsData({ ...pharmacogenomicsData, section2_subDesc2: singleData });
  };
  
  const contentChange5 = (singleData) => {
    setPharmacogenomicsData({ ...pharmacogenomicsData, section2_subDesc3: singleData });
  };
  
  const contentChange6 = (singleData) => {
    setPharmacogenomicsData({ ...pharmacogenomicsData, section5_desc1: singleData });
  };
  
  const contentChange7 = (singleData) => {
    setPharmacogenomicsData({ ...pharmacogenomicsData, section5_desc2: singleData });
  };
  
  const contentChange8 = (singleData) => {
    setPharmacogenomicsData({ ...pharmacogenomicsData, section5_desc3: singleData });
  };
  
  const contentChange9 = (singleData) => {
    setPharmacogenomicsData({ ...pharmacogenomicsData, section5_desc4: singleData });
  };
  
  const contentChange10 = (singleData) => {
    setPharmacogenomicsData({ ...pharmacogenomicsData, section5_desc5: singleData });
  };
  
  const contentChange11 = (singleData) => {
    setPharmacogenomicsData({ ...pharmacogenomicsData, section5_desc6: singleData });
  };
  
  // const contentChange12 = (singleData) => {
  //   setPharmacogenomicsData({ ...pharmacogenomicsData, section4_description: singleData });
  // };
  

  const params = useParams();
  const editor = useRef(null);

  //get single post
  const getPharmacogenomicsDetails = async () => {
    console.log(params);
    let result = await fetch(
      API_URL + `api/pharmacogenomics/singledata/${params.id}`
    );
    result = await result.json();
    setPharmacogenomicsData(result?.singleData);
    console.log(result);
  };
  useEffect(() => {
    getPharmacogenomicsDetails();
  }, []);

  const updatePharmacogenomics = async (e) => {
    e.preventDefault();
    let result = await fetch(
      API_URL + `api/pharmacogenomics/update/${params.id}`,
      {
        method: "PUT",
        body: JSON.stringify(pharmacogenomicsData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    result = await result.json();
    toast.success("updated successfully.");
    navigate("/pharmacogenomics");
  };

  const handleBack = () => {
    navigate("/pharmacogenomics");
  };

  return (
    <div>
      <button className="btn btn-primary ms-3 mt-2" onClick={handleBack}>
        <i className="fa-solid fa-backward me-2"></i>
        back
      </button>

      <div className="containerform">
        <h2 className="pageTitle">Edit Pharmacogenomics Sections</h2>

        {/* "handleSubmit" will validate your inputs before invoking "onSubmit"  */}
        <form onSubmit={(e) => updatePharmacogenomics(e)} autoComplete="off">
          <div className="sectionHolder">
            <h4> Section 1</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section1_title"
                    value={section1_title}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                  Description
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={pharmacogenomicsData?.section1_desc}
                    onChange={(newContent) => contentChange1(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 2</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_title"
                    value={section2_title}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                  Description
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={pharmacogenomicsData?.section2_desc}
                    onChange={(newContent) => contentChange2(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    sub-description-1
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={pharmacogenomicsData?.section2_subDesc1}
                    onChange={(newContent) => contentChange3(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    sub-description-2
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={pharmacogenomicsData?.section2_subDesc2}
                    onChange={(newContent) => contentChange4(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    sub-description-3
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={pharmacogenomicsData?.section2_subDesc3}
                    onChange={(newContent) => contentChange5(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 3</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section3_title"
                    value={section3_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    fact-1
                  </label>
                  <br />
                  <textarea
                    name="section3_fact1"
                    id="description"
                    value={section3_fact1}
                    rows={5}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></textarea>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    fact-2
                  </label>
                  <br />
                  <textarea
                    name="section3_fact2"
                    id="description"
                    value={section3_fact2}
                    rows={5}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></textarea>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    fact-3
                  </label>
                  <br />
                  <textarea
                    name="section3_fact1"
                    id="description"
                    value={section3_fact3}
                    rows={5}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 4</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section4_title"
                    value={section4_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Description
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={pharmacogenomicsData?.section4_description}
                    onChange={(newContent) => contentChange12(newContent)}
                  />
                </div> */}
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 5</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section5_title"
                    value={section5_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    description-1
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={pharmacogenomicsData?.section5_desc1}
                    onChange={(newContent) => contentChange6(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    description-2
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={pharmacogenomicsData?.section5_desc2}
                    onChange={(newContent) => contentChange7(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    description-3
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={pharmacogenomicsData?.section5_desc3}
                    onChange={(newContent) => contentChange8(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    description-4
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={pharmacogenomicsData?.section5_desc4}
                    onChange={(newContent) => contentChange9(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    description-5
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={pharmacogenomicsData?.section5_desc5}
                    onChange={(newContent) => contentChange10(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    description-6
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={pharmacogenomicsData?.section5_desc6}
                    onChange={(newContent) => contentChange11(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 6</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="carrierrisk" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section6_title"
                    value={section6_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mt-2">
            <input
              type="submit"
              value="submit"
              className="btn btn-success border-radius form-control"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CancerEdit;
