import React, { useEffect, useState, useRef } from "react";
import { api_url, API_URL } from "../../config";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import axios from "axios";

function EditArticle() {
  const navigate = useNavigate();
  const [updateData, setUpdateData] = useState({
    title: "",
    description: "",
    file: "",
    category: "",
    tags: "",
  });

  let { title, description, file, category, tags } = updateData;
  // const [image,setImage] = useState();
  // console.log("image",image)
  const [imagePreview, setImagePreview] = useState();
// console.log("image", imagePreview)

console.log("statefile", file)
  function changeText(e) {
    const { name, value } = e.target;
    setUpdateData({ ...updateData, [name]: value });
  }

  const contentChange = (singleData) => {
    setUpdateData({ ...updateData, description: singleData });
  };
  const editor = useRef(null);

  const params = useParams();

    //upload
    const upload = async () => {
      try {
        const formData = new FormData();
        formData.append("file", file);
        console.log("appendedit",file)
        let result = await axios.post(API_URL + "api/upload", formData);
        console.log("uplaod", result.data)
        return result.data;
      } catch (err) {
        console.log(err);
      }
    };

  const getArticleDetails = async () => {
    console.log(params);
    let result = await fetch(API_URL + `api/articles/singledata/${params.id}`);
    result = await result.json();
    setUpdateData(result.singleData);
    console.log(result);
  };

  useEffect(() => {
    getArticleDetails();
  }, []);


  

  //update
  const updateArticle = async (e) => {
    e.preventDefault();
    const imgUrl = await upload();
    console.log("imgUrl",imgUrl)
    let result = await fetch(
      API_URL + `api/articles/updateArticle/${params.id}`,
      {
        method: "PUT",
        body: JSON.stringify(updateData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    result = await result.json();
    toast.success("updated successfully.");
    console.log("edit result",result)
    navigate("/articles");
  };

  const onFileChange = (e) => {
    setUpdateData(e.target.files[0]);
    // console.log(file)
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    };
  };

  const handleBack = () => {
    navigate("/articles");
  };

  return (
    <>
      <button className="btn btn-primary ms-3 mt-2" onClick={handleBack}>
        <i className="fa-solid fa-backward me-2"></i>
        back
      </button>

      <div className="containerform">
        <h2 className="pageTitle">Edit Article</h2>

        {/* "handleSubmit" will validate your inputs before invoking "onSubmit"  */}
        <form onSubmit={(e) => updateArticle(e)} autoComplete="off" enctype="multipart/form-data">
          <div className="form-group">
            <label htmlFor="articles" className="fw-bold">
              Title
            </label>
            <br />
            <input
              type="text"
              id="title"
              className="form-control border-dark mt-2"
              placeholder="Enter your title"
              name="title"
              value={title}
              onChange={changeText}
            />
          </div>

          {/* answer */}

          <div className="form-group" class="fw-bold">
            <label htmlFor="articles" className="mt-2">
              Description
            </label>
            <br />
            <JoditEditor
              ref={editor}
              value={updateData?.description}
              onChange={(newContent) => contentChange(newContent)}
            />
          </div>

          <div className="form-group" class="fw-bold">
            <label htmlFor="articles" className="mt-2">
              Image
            </label>
            <br />
            <div>
            <input type="file" onChange={onFileChange} />
            </div>
            <h6 className="" >Preview Image: </h6>
                {/* <img src={file} width={200} height={100} /> */}
                <img
                  src={`/uploads/${file}`}
                  width={400}
                  height={300}
                  alt="choose image"
                />
          </div>

          <div className="form-group">
            <label htmlFor="articles" className="fw-bold">
              Category
            </label>
            <br />
            <input
              type="text"
              id="category"
              className="form-control border-dark mt-2"
              placeholder="Enter your title"
              name="category"
              value={category}
              onChange={changeText}
            />
          </div>
          <div className="form-group">
            <label htmlFor="articles" className="fw-bold">
              Tags
            </label>
            <br />
            <input
              type="text"
              id="tags"
              className="form-control border-dark mt-2"
              placeholder="Enter your title"
              name="tags"
              value={tags}
              onChange={changeText}
            />
          </div>
          <div className="form-group mt-2">
            <input
              type="submit"
              value="update"
              className="btn btn-primary border-radius form-control"
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default EditArticle;
