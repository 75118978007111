import React, { useEffect, useState } from "react";
import { useParams,useNavigate,Link } from "react-router-dom";
import {API_URL } from "../../config/index";

function Pharmacogenomics() {
  const [pharmacogenomics, setPharmacogenomics] = useState([]);
  const params = useParams();

  const getPharmacogenomicsDetails = async () => {
    let result = await fetch(API_URL + `api/pharmacogenomics/getall`);
    result = await result.json();
    setPharmacogenomics(result?.data);
    console.log("resultpharma", result);
  };

  useEffect(() => {
    getPharmacogenomicsDetails();
  }, []);

  const navigate = useNavigate();
  const handleEdit = () => { 
    navigate("/editpharmacogenomics");
  };

  return (
      <div className="adultOnsetContainer" style={{height:"100vh"}}>
      <h4>Edit Pharmacogenomics Post</h4>
      <span>Here you can edit pharmacogenomics details:</span>
        {pharmacogenomics.map((item) => {
          return (
            <div>

            <table class="table text-center table-bordered mt-4">
            <thead>
              <tr>
                <th scope="col">Sl No.</th>
                <th scope="col">Page Title</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">1</td>
                <td>Pharmacogenomics Page</td>
                <td>
                <Link to={`/editpharmacogenomics/${item._id}`}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={handleEdit}
                >
                  Edit Post
                </button>
              </Link>
                </td>
              </tr>
            </tbody>
          </table>
             
            </div>
          );
        })}
      </div>
  );
}

export default Pharmacogenomics;
