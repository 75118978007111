import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { API_URL } from "../../config";

function Metabolic() {
  const [allMetabolicDetails, setAllMetabolicDetails] = useState([]);
  const params = useParams();

  const getMetabolicDetails = async () => {
    console.log(params);
    let result = await fetch(API_URL + `api/adultOnset/allmetabolic`);
    result = await result.json();
    setAllMetabolicDetails(result?.data);
    console.log("result", result);
  };

  useEffect(() => {
    getMetabolicDetails();
  }, []);

  const navigate = useNavigate();
  const handleEdit = () => {
    navigate("/cancerEdit");
  };

  return (
    <div className="adultOnsetContainer" style={{height:"100vh"}}>
      <h4>Edit Metabolism Post</h4>
      <span>Here you can edit the metabolism details:</span>

      {allMetabolicDetails.map((item) => {
        return (
         <div>
         <table class="table text-center table-bordered mt-4">
            <thead>
              <tr>
                <th scope="col">Sl No.</th>
                <th scope="col">Page Title</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">1</td>
                <td>Metabolism Page</td>
                <td>
                  <Link to={`/editmetabolic/${item._id}`}>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={handleEdit}
                    >
                      Edit Post
                    </button>
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
         </div>
        );
      })}
    </div>
  );
}

export default Metabolic;
