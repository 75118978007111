import React from 'react'
import "./accountdetails.css"
import { useNavigate } from 'react-router-dom';
function AccountDetails() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/dashboard");
  };

  return (
    <>
       <button className="btn btn-primary ms-3 mt-2" onClick={handleBack}>
        <i className="fa-solid fa-backward me-2"></i>
        back to dashboard
      </button>

      <div className='account-card'>
    
    <h4>Admin Account Details : </h4>
        <div class="card mt-4 card-style bg-transparent">
  <div class="card-body d-flex">
    <div>
    <h5 class="card-title">Name : </h5>
   <hr />
    <h5 class="card-title">Email : </h5>
  

    </div>

    <div className='ms-2 text-danger'>
    <h5 class="card-text">Strand Admin </h5>
   <hr />
    <h5 class="card-text">strandadmin@gmail.com</h5>

    </div>

  </div>
</div>
    </div>
    </>
 
  )
}

export default AccountDetails