import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { API_URL } from "../../config/index";
import "../../app.css";
import { useNavigate, Link } from "react-router-dom";
import CreateFaqForm from "./CreateFaqForm";
import EditFaq from "./EditFaq";
import { toast } from "react-toastify";


function Faq() {
  const navigate = useNavigate();
  //   const [question, setQuestion] = useState();
  //   const [answer, setAnswer] = useState();

  const [allFaqs, setAllFaqs] = useState([]);
  const [selectedFaq, setSelectedFaq] = useState();
  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
  });
  // const idProductRef = useRef();

  const toggle = (i) => {
    if (selectedFaq === i) {
      return setSelectedFaq(null);
    }
    setSelectedFaq(i);
  };
  //   dialog;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  //get all faqs
  const getData = async () => {
    let result = await fetch(API_URL + "api/faq/faqs");
    let res = await result.json();
    setAllFaqs(res.data);
    console.log("result", res);
  };

  useEffect(() => {
    getData();
  }, []);

  function openClose() {
    setOpen(true);
    navigate("/");
  }

  //   delete

  async function deleteFaq(id) {
    let result = await fetch(API_URL + `api/faq/deleteFaq/${id}`, {
      method: "Delete",
    });
    result = await result.json();
    toast.success("faq deleted successfully")
    console.log("deleted result", result);
    if (result) {
      getData();
    }
  }

  return (
    <div>
      <div className="wrapper">
        <div className="d-flex justify-content-between align-content-center">
        <h2 className="pageTitle">Frequently Asked Question's</h2>
        <Link to="/createFaq">
          <div onClick={openClose}>
            {/* Create a new faq */}
            <i class="fa-solid fa-circle-plus fa-3x"></i>
            {open ? <CreateFaqForm /> : ""}
          </div>
        </Link>
        </div>

        <div className="accordion">
          {allFaqs.map((item, i) => {
            return (
              <div className="item">
                <div className="faqTitle" onClick={() => toggle(i)}>
                  <h6>{item.question}</h6>
                  <span>{selectedFaq === i ? "-" : "+"}</span>
                </div>

                <div className={selectedFaq === i ? "content show" : "content"}>
                  <p>{item.answer}</p>
                </div>
                {selectedFaq === i ? (
                  <div style={{ display: "flex", justifyContent: "flex-end"}}>
                    <Link to={`/editFaq/${item._id}`}>
                      <button onClick={openClose} style={{border:"none",color:"blue"}}>
                        {/* Create a new faq */}
                        <i class="fa-solid fa-pen-to-square"></i>
                        {open ? <EditFaq /> : ""}
                      </button>
                    </Link>

                    <button
                      style={{ color: "red", marginLeft:"10px", border:"none" }}
                      onClick={() => {
                        window.confirm("Are you sure want to delete?") &&
                          deleteFaq(item._id);
                      }}
                    >
                      <i class="fa-solid fa-trash"></i>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Faq;
