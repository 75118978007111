import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config/index";

function Dashboard() {
  const [contacts, setContacts] = useState([]);
  const [consults, setConsults] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getAllContacts();
  }, []);
  const getAllContacts = async () => {
    let result = await fetch(API_URL + "api/contact/getall");
    let res = await result.json();
    setContacts(res.data);
    console.log("all contacts", res);
  };

  useEffect(() => {
    getAllConsults();
  }, []);
  const getAllConsults = async () => {
    let result = await fetch(API_URL + "api/consult/getall");
    let res = await result.json();
    setConsults(res.data);
    console.log("all consults", res);
  };
  const handleContactnavigate = () => {
    navigate("/contact-us");
  };
  const handleConsultnavigate = () => {
    navigate("/consult");
  };
  return (
    <div className="p-4 home-container-style">
      <h1 className="text-primary pb-3">Welcome Admin , </h1>

      <div>
        <div className="d-flex justify-content-between">
          <h5>
            Recent Enquiries (
            <i className="text-warning">recent five contacts</i>) :
          </h5>

          {contacts.length > 0 ? (
            <p
              className="fw-bold viewmore"
              style={{ cursor: "pointer" }}
              onClick={handleContactnavigate}
            >
              view more....
            </p>
          ) : (
            ""
          )}
        </div>

        <div className="row mt-3">
          <div className=" table-responsive">
            <table className="table text-dark rounded-3 table-bordered ">
              {contacts.length > 0 ? (
                <thead className="fs-5">
                  <tr>
                    <th scope="col">Sl.No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Message</th>
                  </tr>
                </thead>
              ) : (
                ""
              )}
              <tbody>
                {contacts.length > 0 ? (
                  //to show the last 5 contacts only
                  contacts
                    .slice(Math.max(contacts.length - 5))
                    .map((item, id) => {
                      return (
                        <>
                          <tr key={id}>
                            <td>{id + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.mobile}</td>
                            <td className="overflow-hidden">{item.message}</td>
                          </tr>
                        </>
                      );
                    })
                ) : (
                  <h3 className="text-danger">No Result Found</h3>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="d-flex justify-content-between">
          <h5>Recent Consults :</h5>
          {consults.length > 0 ? (
            <p
              className="fw-bold viewmore"
              style={{ cursor: "pointer" }}
              onClick={handleConsultnavigate}
            >
              view more....
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="row mt-3">
          <div className="table-responsive">
            <table className="table text-dark rounded-3 table-bordered ">
              {consults.length > 0 ? (
                <thead className="fs-5">
                  <tr>
                    <th scope="col">Sl.No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Message</th>
                  </tr>
                </thead>
              ) : (
                ""
              )}
              <tbody>
                {consults.length > 0 ? (
                  consults
                    .slice(Math.max(consults.length - 5, 0))
                    .map((item, id) => {
                      return (
                        <>
                          <tr key={id}>
                            <td>{id + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.mobile}</td>
                            <td className="overflow-hidden">{item.message}</td>
                          </tr>
                        </>
                      );
                    })
                ) : (
                  <h3 className="text-danger">No Result Found</h3>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
