import React, { useState, useRef, useMemo } from "react";
import { API_URL } from "../../config/index";
import { useNavigate } from "react-router-dom";
import "../../app.css";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

function CreateFaqForm() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    question: "",
    answer: "",
  });
  const { question, answer } = data;

  function changeText(e) {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }

  const handleCreate = async (e) => {
    e.preventDefault();
    console.log("called");
    let result = await fetch(API_URL + "api/faq/createFaq", {
      method: "POST",
      body: JSON.stringify(data, answer),
      headers: {
        "Content-Type": "application/json",
      },
    });
    result = await result.json();
    toast.success("faq created successfully");
    navigate("/faqs");
    console.log("resultFaqs", result);
  };

  const handleBack = () => {
    navigate("/faqs");
  };

  return (
    <div>
      <button className="btn btn-primary ms-3 mt-2" onClick={handleBack}>
        <i className="fa-solid fa-backward me-2"></i>
        back
      </button>
      <div className="containerform">
        <h2 className="pageTitle">Create Frequently Asked Question's</h2>

        {/* "handleSubmit" will validate your inputs before invoking "onSubmit"  */}
        <form onSubmit={handleCreate} method="POST" autoComplete="off">
          <div className="form-group">
            <label htmlFor="fullname" className="fw-bold">
              Question
            </label>
            <br />
            <input
              type="text"
              id="question"
              className="form-control mt-2"
              placeholder="Enter your question"
              name="question"
              required
              value={question}
              onChange={changeText}
            />
          </div>

          {/* answer */}

          <div className="form-group" className="fw-bold">
            <label htmlFor="address" className="mt-2">
              Answer
            </label>
            <br />
            <textarea
              name="answer"
              id="answer"
              rows={10}
              placeholder="Enter your Answer"
              className="form-control mt-2"
              value={answer}
              onChange={changeText}
            ></textarea>
          </div>

          <div className="form-group mt-2">
            <input
              type="submit"
              value="submit"
              className="btn btn-success border-radius form-control"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateFaqForm;
