import React, { useEffect, useState, useRef } from "react";
import { API_URL } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

function CancerEdit() {
  
  const navigate = useNavigate();

  const [allCancerPost, setAllCancerPost] = useState({
    section1_title:"",
    section1_description:"",
    section2_title:"",
    section2_description:"",
    section2_subttile1:"",
    section2_subdesc1:"",
    section2_subttile2:"",
    section2_subdesc2:"",
    section2_subttile3:"",
    section2_subdesc3:"",
    section3_title:"",
    section3_image:[],
    section4_title:"",
    section4_image:[],
  });

  const {
    section1_title,
    section1_description,
    section2_title,
    section2_description,
    section2_subttile1,
    section2_subdesc1,
    section2_subttile2,
    section2_subdesc2,
    section2_subttile3,
    section2_subdesc3,
    section3_title,
    section3_image,
    section4_title,
    section4_image,
  } = allCancerPost;

  function changeText(e) {
    const { name, value } = e.target;
    setAllCancerPost({ ...allCancerPost, [name]: value });
  }

  const contentChange = (singleData) => {
    setAllCancerPost({ ...allCancerPost, section1_description: singleData });
  };

  const contentChange2 = (singleData) => {
    setAllCancerPost({ ...allCancerPost, section2_description: singleData });
  };

  
  const contentChange3 = (singleData) => {
    setAllCancerPost({ ...allCancerPost, section2_subdesc1: singleData });
  };

  
  const contentChange4 = (singleData) => {
    setAllCancerPost({ ...allCancerPost, section2_subdesc2: singleData });
  };

  
  const contentChange5 = (singleData) => {
    setAllCancerPost({ ...allCancerPost, section2_subdesc3: singleData });
  };
  const params = useParams();

  //editor
  const editor = useRef(null);

  //get single post
  const getCancerPostDetails = async () => {
    console.log(params);
    let result = await fetch(
      API_URL + `api/adultOnset/singledata/${params.id}`
    );
    result = await result.json();
    setAllCancerPost(result?.singleData);
    console.log(result);
  };
  useEffect(() => {
    getCancerPostDetails();
  }, []);

  const updateCancer = async (e) => {
    e.preventDefault();
    let result = await fetch(
      API_URL + `api/adultOnset/updateCancerPost/${params.id}`,
      {
        method: "PUT",
        body: JSON.stringify(allCancerPost),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    result = await result.json();
    toast.success("updated successfully.");
    console.log("update result", result);
    navigate("/cancer");
  };

  const handleBack = () => {
    navigate("/cancer");
  };

  return (
    <div>
      <button className="btn btn-primary ms-3 mt-2" onClick={handleBack}>
        <i className="fa-solid fa-backward me-2"></i>
        back
      </button>

      <div className="containerform">
        <h2 className="pageTitle">Edit Cancer Sections</h2>

        {/* "handleSubmit" will validate your inputs before invoking "onSubmit"  */}
        <form onSubmit={(e) => updateCancer(e)} autoComplete="off">
          <div className="sectionHolder">
            <h4> Section 1</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section1_title"
                    value={section1_title}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCancerPost?.section1_description}
                    onChange={(newContent) => contentChange(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 2</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_title"
                    value={section2_title}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Description
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCancerPost?.section2_description}
                    onChange={(newContent) => contentChange2(newContent)}
                  />
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    subTitle-1
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_subttile1"
                    value={section2_subttile1}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                   subDescription-1
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCancerPost?.section2_subdesc1}
                    onChange={(newContent) => contentChange3(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    subTitle-2
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_subttile2"
                    value={section2_subttile2}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                   subDescription-2
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCancerPost?.section2_subdesc2}
                    onChange={(newContent) => contentChange4(newContent)}
                  />
                </div>

                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    subTitle-3
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section2_subttile3"
                    value={section2_subttile3}
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                   subDescription-3
                  </label>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={allCancerPost?.section2_subdesc3}
                    onChange={(newContent) => contentChange5(newContent)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sectionHolder">
            <h4> Section 3</h4>
            <div className="card">
              <div className="card-body">
                <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section3_title"
                    value={section3_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    
                  </label>
                  <br />
                  <div style={{cursor:"pointer"}}>
                   <input type="file" />
                    <span>preview image</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>


          <div className="sectionHolder">
            <h4> Section 4</h4>
            <div className="card">
              <div className="card-body">

              <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    Title
                  </label>
                  <br />
                  <input
                    type="text"
                    name="section4_title"
                    value={section4_title}
                    id="title"
                    placeholder="Enter your Answer"
                    className="form-control mt-2"
                    onChange={changeText}
                  ></input>
                </div>

                {/* <div className="form-group" className="fw-bold">
                  <label htmlFor="address" className="mt-2">
                    
                  </label>
                  <br />
                  <div style={{cursor:"pointer"}}>
                   <input type="file" />
                    <span>preview image</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

      
         
          <div className="form-group mt-2">
            <input
              type="submit"
              value="submit"
              className="btn btn-success border-radius form-control"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CancerEdit;
